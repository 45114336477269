export default {
  metaInfo () {
    const title = this.metaTitle ?? "L'impact de ton alimentation en temps réel"
    const description = this.metaDescription ?? "Glucie est le premier programme santé personnalisé pour comprendre ton corps, booster ton métabolisme, améliorer ton sommeil, retrouver ton poids idéal, et dire adieu à l'acné!"
    const image = this.metaImage ?? this.asset('img/social_preview.jpg')

    return {
      title: title,
      titleTemplate: '%s · Deeplome',
      meta: [
        ...(this.metaTags ?? []),
        { vmid: 'og:title', property: 'og:title', content: title },
        { vmid: 'twitter:title', property: 'twitter:title', content: title },
        { vmid: 'description', name: 'description', content: description },
        { vmid: 'og:description', property: 'og:description', content: description },
        { vmid: 'twitter:description', property: 'twitter:description', content: description },
        { vmid: 'twitter:image', property: 'twitter:image', content: image },
        { vmid: 'og:image', property: 'og:image', content: image }
      ]
    }
  }
}
