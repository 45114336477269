import Vue from 'vue'
import store from '~/store'
import router from '~/router'
import App from '~/components/App.vue'
import LoadScript from 'vue-plugin-load-script'
import Base from './base'
import i18n from './plugins/i18n'
import AOS from 'aos/dist/aos.js'
import 'aos/dist/aos.css'
import * as Sentry from "@sentry/vue";

import '~/plugins'
import '~/components'

import '../sass/app.scss'

Vue.config.productionTip = false
Vue.config.performance = true

Vue.mixin(Base)
Vue.use(LoadScript)

AOS.init()

Sentry.init({
  Vue,
  dsn: "https://bcc2c6e46964a39f5d99ae0420e5351e@o4507183544074240.ingest.de.sentry.io/4507183546171472",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.glucie\.com\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

/* eslint-disable no-new */
new Vue({
  store,
  router,
  i18n,
  AOS,
  ...App
})
