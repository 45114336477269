import { Preferences } from '@capacitor/preferences'

import * as types from '../mutation-types'


async function setPreference (key, value) {
  return await Preferences.set({ key, value: JSON.stringify(value) });
}

// state
export const state = {
    isOpen: false,
}

// getters
export const getters = {
  isOpen: state => state.isOpen
}

// mutations
export const mutations = {
  [types.SET_IS_OPEN](state, isOpen) {
    state.isOpen = isOpen;
  }
};

// actions
export const actions = {
  async setIsOpen ({ commit }, payload) {
    await setPreference('isOpen', payload.isOpen)
    commit(types.SET_IS_OPEN, payload.isOpen);
  }
}