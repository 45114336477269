<template>
  <router-view />
</template>

<script>
export default {
  name: 'NoneLayout',

  components: {
  },

  data: function () {
    return {

    }
  },

  mounted () {
  },

  methods: {}
}
</script>
