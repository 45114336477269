export default (to, from, next) => {
  // For now, enable the web version
  next()

  // if (window.config.is_mobile_app) {
  //   next()
  // } else if (to.name !== 'answers.show') {
  //   window.location.href = window.config.landing_url
  // } else {
  //   next()
  // }
}
