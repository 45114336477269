import axios from 'axios'
import { formatDateString } from './dates'

export async function fetchMeals (selectedDate, timezone) {
    const date = formatDateString(selectedDate)
    timezone = timezone.replace("/", "-")
    const response = await axios.get(`/meals/${date}/${timezone}`)
    return response.data
}
export async function fetchExercises (selectedDate, timezone) {
    const date = formatDateString(selectedDate)
    timezone = timezone.replace("/", "-")
    const response = await axios.get(`/exercises/${date}/${timezone}`)
    return response.data
}
export async function fetchSleep (selectedDate, timezone) {
    const date = formatDateString(selectedDate)
    timezone = timezone.replace("/", "-")
    const response = await axios.get(`/all-sleep/${date}/${timezone}`)
    return response.data
}
export async function fetchCGMReadings (selectedDate, timezone) {
    let cgmTimestamps = []
    let cgmValues = []
    const date = formatDateString(selectedDate)
    timezone = timezone.replace("/", "-")
    const response = await axios.get(`/cgm/${date}/${timezone}`)
    const cgmReadings = response.data
    cgmReadings.forEach((reading) => {
        cgmTimestamps.push(reading.timestamp)
        cgmValues.push(reading.data)
    })
    return [cgmTimestamps, cgmValues]
}
export async function getSensor() {
    const response = await axios.get('sensor')
    return response.data
}

export async function getOnboardingSteps() {
    const response = await axios.get('/onboarding')
    return response.data
}

export async function getSubscription() {
    const response = await axios.get('subscription')
    return response.data
}

export async function getPrices() {
    const response = await axios.get('prices')
    return response.data
}