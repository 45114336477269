<template>
  <div :class="wrapperClass">
    <label v-if="label"
           :class="[theme.default.label,{'uppercase text-xs':uppercaseLabels, 'text-sm':!uppercaseLabels}]"
    >
      {{ label }}
      <span v-if="required" class="text-red-500 required-dot">*</span>
    </label>
    <span class="inline-block w-full rounded-md shadow-sm">
      <button :class="[theme.default.input,inputClass,{'ring-red-500 ring-2': hasValidation && form.errors.has(name)}]" :style="inputStyle" aria-expanded="true" aria-haspopup="listbox"
              aria-labelledby="listbox-label"
              class="cursor-pointer relative w-full"
              type="button" @click.prevent="showUploadModal=true">
        <div v-if="currentUrl==null" class="h-6 text-gray-600 dark:text-gray-400">
          Upload image
          <svg class="h-6 w-6 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </svg>
        </div>
        <div v-else class="h-6 text-gray-600 dark:text-gray-400 flex">
          <div class="flex-grow">
            <img :src="currentUrl" class="h-6 rounded shadow-md">
          </div>
          <a class="hover:text-nt-blue flex" href="#" @click.prevent="clearUrl">
            <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </svg>
          </a>
        </div>
      </button>
    </span>
    <small v-if="help" :class="theme.default.help">
      <slot name="help">{{ help }}</slot>
    </small>
    <has-error v-if="hasValidation" :field="name" :form="form"/>

    <!--  Modal  -->
    <modal :show="showUploadModal" @close="showUploadModal=false">
      <h2 class="text-lg font-semibold">
        Upload an image
      </h2>

      <div class="max-w-3xl mx-auto lg:max-w-none">
        <div class="sm:mt-5 sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start sm:pt-5">
          <div class="mt-2 sm:mt-0 sm:col-span-2 mb-5">
            <div
              v-cloak
              class="w-full flex justify-center items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md h-128"
              @dragover.prevent="onUploadDragoverEvent($event)"
              @drop.prevent="onUploadDropEvent($event)"
            >
              <div v-if="loading" class="text-gray-600 dark:text-gray-400">
                <loader class="h-6 w-6 mx-auto m-10"/>
                <p class="text-center mt-6">
                  Uploading your file...
                </p>
              </div>
              <template v-else>
                <div
                  :class="{
                    'opacity-100': uploadDragoverTracking,
                    'opacity-0': !uploadDragoverTracking
                  }"
                  class="absolute rounded-full bg-gray-100 h-20 w-20 z-10 transition-opacity duration-500 ease-in-out"
                />
                <div class="relative z-20 text-center">
                  <input ref="actual-input" :name="name" accept="image/png, image/gif, image/jpeg, image/bmp" class="hidden"
                         type="file" @change="manualFileUpload"
                  >
                  <svg class="mx-auto h-24 w-24 text-gray-200" fill="none" stroke="currentColor"
                       viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2"
                    />
                  </svg>
                  <p class="mt-5 text-sm text-gray-600">
                    <button
                      class="font-semibold text-nt-blue hover:text-nt-blue-dark focus:outline-none focus:underline transition duration-150 ease-in-out"
                      type="button"
                      @click="openFileUpload"
                    >
                      Upload your image
                    </button>
                    or drag and drop
                  </p>
                  <p class="mt-1 text-xs text-gray-500">
                    .jpg, .jpeg, .png, .bmp, .gif up to 5mb
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '../Modal.vue'
import inputMixin from '~/mixins/forms/input'
import { resizeAndBase64EncodeImage } from '../../helpers/images'

export default {
  name: 'ImageInput',

  components: { Modal },
  mixins: [inputMixin],
  props: {
    signedUrlEndpoint: {
      type: String,
      default: '/vapor/signed-storage-url'
    }
  },

  watch: {
    file: {
      deep: true,
      handler (file) {
        this.compVal = file.name
      }
    }
  },

  mounted () {
    if (this.compVal) {
      this.file = {
        url: this.compVal,
        name: this.compVal
      }
    }
  },

  data: () => ({
    showUploadModal: false,

    file: [],
    uploadDragoverTracking: false,
    uploadDragoverEvent: false,
    loading: false
  }),

  computed: {
    currentUrl () {
      return this.file ? this.file.url : null
    }
  },

  methods: {
    clearUrl () {
      this.file = []
      this.compVal = null
    },
    onUploadDragoverEvent (e) {
      this.uploadDragoverEvent = true
      this.uploadDragoverTracking = true
    },
    onUploadDropEvent (e) {
      this.uploadDragoverEvent = false
      this.uploadDragoverTracking = false
      this.droppedFiles(e)
    },
    droppedFiles (e) {
      const droppedFiles = e.dataTransfer.files

      if (!droppedFiles) return

      this.file = droppedFiles[0]
      this.uploadFileToServer()
    },
    openFileUpload () {
      this.$refs['actual-input'].click()
    },
    manualFileUpload (e) {
      this.file = e.target.files[0]
      this.uploadFileToServer()
    },
    uploadFileToServer () {
      this.loading = true
      const resizedFile = resizeAndBase64EncodeImage(this.file)
      // Store file in s3
      this.storeFile(resizedFile, {
        visibility: 'public-read'
      }).then(response => {
        this.file = {
          url: response.short_url,
          name: resizedFile.name.split('.').slice(0, -1).join('.') + '_' + response.uuid + '.' + response.extension
        }

        this.showUploadModal = false
        this.loading = false
      }).catch((error) => {
        this.compVal = null
        this.showUploadModal = false
        this.loading = false
      })
    }
  }
}
</script>
