<template>
  <div id="app" class="bg-white relative">
    <loading v-show="!isMobileApp" ref="loading" />
    <subscribe-push-notifications v-if="authenticated && isMobileApp" />
    <transition name="">
      <!--   Connection issues   -->
      <div v-if="!connected" class="min-h-screen flex flex-col bg-white pb-20 relative justify-center">
        <h1 class="text-center text-5xl">
          🤕
        </h1>
        <p class="font-semibold text-center mt-4">
          {{$t('app.noInternet.title')}}
        </p>
        <p class="text-center mt-2">
          {{$t('app.noInternet.description')}}
        </p>
      </div>
      <component :is="layout" v-else-if="layout" />
    </transition>
    <portal-target name="modals" multiple />
    <notifications />
  </div>
</template>

<script>
import Loading from './Loading.vue'
import StopImpersonation from './pages/StopImpersonation.vue'
import Notifications from './common/Notifications.vue'
import SeoMeta from '../mixins/seo-meta'
import { SplashScreen } from '@capacitor/splash-screen'
import { mapGetters } from 'vuex'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import SubscribePushNotifications from './SubscribePushNotifications.vue'
import { Network } from '@capacitor/network'
import { Capacitor } from '@capacitor/core';
import axios from 'axios'

// Load layout components dynamically.
const requireContext = import.meta.glob('../layouts/**.vue', { eager: true })

const layouts = {}
Object.keys(requireContext)
  .map(file =>
    [file.match(/[^/]*(?=\.[^.]*$)/)[0], requireContext[file]]
  )
  .forEach(([name, component]) => {
    layouts[name] = component.default || component
  })

export default {
  el: '#app',

  components: {
    SubscribePushNotifications,
    Notifications,
    StopImpersonation,
    Loading
  },

  mixins: [SeoMeta],

  data: () => ({
    metaTitle: 'Avec notre capteur et l\'appli Glucie, analyse l\'impact de ton alimentation sur ton corps en temps réel',
    metaDescription: 'Glucie est la première appli qui t\'aide à vraiment prendre en main ta nutrition en comprenant comment ton corps réagit à différent plat et aliment!',
    layout: null,
    defaultLayout: 'default',
    // Has valid interned connection
    connected: true
  }),
  computed: {
    ...mapGetters({
      authenticated: 'auth/check'
    })
  },

  async mounted () {
    if (this.isMobileApp) {
      CapacitorUpdater.notifyAppReady()

      this.setStatusBarStyle('light')

      try {
        SplashScreen.hide()
      } catch (e) {}

      // Make sure internet is connected
      Network.addListener('networkStatusChange', status => {
        this.connected = status.connected
      })
    }
    this.$loading = this.$refs.loading
  },

  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout (layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }

      this.layout = layouts[layout]
    }
  }
}
</script>
