<template>
  <div :class="wrapperClass" :style="inputStyle">
    <label v-if="label" :for="id?id:name"
           :class="[{'uppercase text-xs':uppercaseLabels, 'text-sm':!uppercaseLabels}]"
    >
      {{ label }}
      <span v-if="required" class="text-red-500 required-dot">*</span>
    </label>
    <div class="relative">
      <input v-if="inputType=='input'" :id="id?id:name" v-model="compVal" :disabled="disabled"
            :type="isPasswordType ? (showPassword ? 'text' : 'password') : nativeType"
            :style="inputStyle" :pattern="pattern" :autocomplete="autocomplete" :inputmode="inputmode"
            :class="[theme.default.input,{ 'ring-red-500 ring-2': hasValidation && form.errors.has(name), 'cursor-not-allowed bg-gray-200':disabled },]"
            :name="name" :accept="accept"
            :placeholder="placeholder" :min="min" :max="max" :maxlength="maxCharLimit"
            @change="onChange" @keydown.enter.prevent="onEnterPress"
      >
      <textarea v-if="inputType=='textarea'" :id="id?id:name" v-model="compVal" :disabled="disabled"
            :style="inputStyle" :pattern="pattern" :autocomplete="autocomplete" :inputmode="inputmode"
            :class="[theme.default.input,{ 'ring-red-500 ring-2': hasValidation && form.errors.has(name), 'cursor-not-allowed bg-gray-200':disabled },]"
            class="text-wrap break-words"
            :name="name" :accept="accept"
            :placeholder="placeholder" :min="min" :max="max" :maxlength="maxCharLimit"
            @change="onChange" @keydown.enter.prevent="onEnterPress"
      ></textarea>
      <!-- Add a toggle icon for password fields -->
      <div v-if="isPasswordType" class="password-toggle absolute inset-y-0 right-0 pr-3 flex items-center" @click="togglePassword">
        <!-- Replace with your SVG or icon code -->
        <div v-if="showPassword">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
          </svg>
        </div>
        <div v-else>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          </svg>
        </div>
      </div>
      </div>
      <div v-if="help || showCharLimit" class="flex">
        <small v-if="help" :class="theme.default.help" class="flex-grow">
          <slot name="help">{{ help }}</slot>
        </small>
        <small v-else class="flex-grow" />
        <small v-if="showCharLimit && maxCharLimit" :class="theme.default.help">
          {{ charCount }}/{{ maxCharLimit }}
        </small>
      </div>
      <has-error v-if="hasValidation" :form="form" :field="name" />
    </div>
</template>

<script>
import inputMixin from '~/mixins/forms/input'
export default {
  name: 'TextInput',

  mixins: [inputMixin],

  props: {
    nativeType: { type: String, default: 'text', password: 'password' },
    pattern: { type: String, default: '' },
    autocomplete: { type: String, default: '' },
    inputmode: { type: String, default: '' },
    accept: { type: String, default: null },
    min: { type: Number, required: false, default: null },
    max: { type: Number, required: false, default: null },
    maxCharLimit: { type: Number, required: false, default: null },
    showCharLimit: { type: Boolean, required: false, default: false },
    inputType: { type: String, required: false, default: 'input' }
  },

  data: () => ({
    showPassword: false
  }),

  computed: {
    compVal: {
      set (val) {
        if (this.form) {
          this.$set(this.form, this.nativeType !== 'file' ? this.name : 'file-' + this.name, val)
        } else {
          this.content = val
        }
        if (this.hasValidation) {
          this.form.errors.clear(this.name)
        }
        this.$emit('input', val)
      },
      get () {
        if (this.form) {
          return this.form[this.nativeType !== 'file' ? this.name : 'file-' + this.name]
        }
        return this.content
      }
    },
    charCount () {
      return (this.compVal) ? this.compVal.length : 0
    },
    isPasswordType () {
      return this.nativeType === 'password'
    }
  },

  watch: {},

  created () {},

  methods: {
    onChange (event) {
      if (this.nativeType !== 'file') return

      const file = event.target.files[0]
      this.$set(this.form, this.name, file)
    },
    /**
     * Pressing enter won't submit form
     * @param event
     * @returns {boolean}
     */
    onEnterPress (event) {
      event.preventDefault()
      return false
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    }
  }
}
</script>
