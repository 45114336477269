/**
 * Base mixin for all Vue components
 */
import debounce from 'debounce'

import { StatusBar, Style } from '@capacitor/status-bar'

export function endpoint (url) {
  return window.config.app_url + url
}

export default {
  computed: {
    $crisp () {
      return window.$crisp
    },
    isMobileApp () {
      return window.config.is_mobile_app
    }
  },
  methods: {
    /**
     * Creates a debounced function that delays invoking a callback.
     */
    debouncer: debounce((callback) => callback(), 500),

    /**
     * Show an error message.
     */
    alertError (message, autoClose = 10000) {
      this.$notify(
        {
          title: 'Erreur',
          text: message,
          type: 'error'
        }, autoClose)
    },

    /**
     * Show a success message.
     */
    alertSuccess (message, autoClose = 10000) {
      this.$notify(
        {
          title: 'Succès',
          text: message,
          type: 'success'
        }, autoClose)
    },

    /**
     * Show a warning message.
     */
    alertWarning (message, autoClose) {
      this.$notify(
        {
          title: 'Attention',
          text: message,
          type: 'warning'
        }, autoClose)
    },

    /**
     * Show confirmation message.
     */
    alertConfirm (message, success, failure, autoClose = 10000) {
      this.$notify(
        {
          title: 'Confirm',
          text: message,
          type: 'confirm',
          success,
          failure
        }, autoClose)
    },

    // openCrisp (message = null) {
    //   this.$crisp.push(['do', 'chat:show'])
    //   this.$crisp.push(['do', 'chat:open'])
    //   this.$crisp.push(['set', 'user:email', this.$store.getters['auth/user'].email])
    //   this.$crisp.push(['set', 'user:nickname', this.$store.getters['auth/user'].name])
    //   if (message !== null) {
    //     this.$crisp.push(['do', 'message:send', ['text', message]])
    //   }
    // },

    openCrisp(message = null, email, nickname) {  
      // Wait for Crisp to be ready or directly interact if already loaded
      window.$crisp.push(['do', 'chat:show']);
      window.$crisp.push(['do', 'chat:open']);
      window.$crisp.push(['set', 'user:email', this.$store.getters['auth/user'].email]);
      window.$crisp.push(['set', 'user:nickname', this.$store.getters['auth/user'].name]);
    
      if (message !== null) {
        window.$crisp.push(['do', 'message:send', ['text', message]]);
      }
    },
    

    endpoint: endpoint,

    setStatusBarStyle (theme) {
      if (!['light', 'dark'].includes(theme.toLowerCase())) {
        throw new Error('Unknown status bar style' + theme)
      }

      StatusBar.setStyle({ style: (theme.toLowerCase() === 'dark' ? Style.Dark : Style.Light) }).catch(() => {})
    }
  }
}
