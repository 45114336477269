<template>
  <transition name="fade">
    <div v-if="showNotificationsModal" key="notifications"
         class="absolute inset-0 bg-white/70 backdrop-blur z-10 px-6"
         :style="{paddingBottom: paddingBottom+'px', paddingTop:paddingTop+'px'}"
    >
      <h1 class="text-black text-center mt-16 text-2xl">
        {{ $t('subscribePushNotifications.title') }}
      </h1>
    </div>
  </transition>
</template>

<script>
import { PushNotifications } from '@capacitor/push-notifications'
import { Device } from '@capacitor/device'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  components: {},

  data: () => ({
    paddingTop: 0,
    paddingBottom: 0,
    showNotificationsModal: false
  }),

  computed: {
    ...mapGetters({
      authenticated: 'auth/check'
    })
  },

  watch: {},

  mounted () {
    try {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        this.paddingTop = insets.top
        this.paddingBottom = insets.bottom
      })
    } catch (e) {
    }

    PushNotifications.addListener('pushNotificationReceived', notification => {
      // Notification is received
    })

    PushNotifications.addListener('pushNotificationActionPerformed', this.performNotificationAction)

    PushNotifications.addListener('registration', this.saveNotificationToken)
    PushNotifications.addListener('registrationError', (err) => {
      this.showNotificationsModal = false
    })
    this.askForNotificationPermission()
  },

  methods: {
    async askForNotificationPermission () {
      let permStatus = await PushNotifications.checkPermissions()

      if (permStatus.receive === 'prompt') {
        this.showNotificationsModal = true
        permStatus = await PushNotifications.requestPermissions()
      }

      await PushNotifications.register()
    },
    async saveNotificationToken (token) {
      this.showNotificationsModal = false
      const deviceInfo = await Device.getInfo()
      axios.patch('/settings/notifications', {
        token: token.value,
        platform: deviceInfo.platform
      })
    },
    performNotificationAction (notificationData) {
      switch (notificationData.notification.data.action.action) {
        case 'see_score_report':
          this.$router.push({
            name: 'score',
            params: { id: notificationData.notification.data.action.params.report_id }
          })
          break
        case 'see_meal_report':
          this.$router.push({
             name: 'meal',
             params: { id: notificationData.notification.data.action.params.meal_id }
          })
          break
      }
      PushNotifications.removeDeliveredNotifications(notificationData.notification)
    }
  }
}
</script>
