import store from '~/store'
import { setUserId, identify, Identify } from '@amplitude/analytics-browser'

export function initCrisp (user) {
  window.$crisp.push(['set', 'user:email', user.email])
  window.$crisp.push(['set', 'user:nickname', user.name])

  window.$crisp.push(['set', 'session:data', [[
    ['id', user.id]
  ]]])
}

export function initAmplitude (user) {
  setUserId('glucie-' + user.id)
  const identifyObj = new Identify()
  identifyObj.set('email', user.email)
  identify(identifyObj)
}

export default async (to, from, next) => {
  if (!store.getters['auth/check'] &&
    store.getters['auth/token'] !== null &&
    store.getters['auth/token'] !== undefined
  ) {
    try {
      const user = await store.dispatch('auth/fetchUser')
      initAmplitude(user)
    } catch (e) {
    }
  }
  next()
}
