import './common'
import './forms'

import Vue from 'vue'
import Child from './Child.vue'
import Modal from './Modal.vue'

import Loader from './common/Loader.vue'

import NotFound from "./pages/NotFound.vue"

// Components that are registered globaly.
[
  Child,
  Modal,
  Loader,
  NotFound
].forEach(Component => {
  Vue.component(Component.name, Component)
})
