<template>
  <div class="bg-white px-8 pt-2 border-t border-gray-200" :class="{'pb-2':paddingBottom===0}">
    <div class="flex justify-center gap-16 mx-auto w-full max-w-xl mb-4" :style="{paddingBottom:paddingBottom+'px'}">
      <div v-for="tab in tabs" :key="tab.route" class="relative flex items-center transition-colors" :class="{
        'text-glucie-blue': tab.title == activeTab,
        'text-gray-500': tab.title != activeTab,
      }">
        <div v-if="tab.title=='appTabs.addActivity'">
          <div class="bg-glucie-blue rounded-full p-1 text-white"><span v-html="tab.svg" /></div>
          <router-link v-track.app_tab_click="{to:tab.route}" :to="{name: tab.route}" class="absolute inset-0" />
        </div>
        <div v-else @click="isActiveTab(tab.title)">
          <router-link v-track.app_tab_click="{to:tab.route}" :to="{name: tab.route}">
            <span v-html="tab.svg" :class="{'text-glucie-blue': tab.title == activeTab}"/>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    paddingTop: 0,
    paddingBottom: 0,
    activeTab: 'appTabs.diary',
    tabs: [
      {
        svg: '<svg class="mx-auto h-8 w-8 py-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">\n'+
            '<path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />\n' +
          '</svg>',
        title: 'appTabs.diary',
        route: 'daily-dashboard',
        highlight_route_prefixes: ['daily-dashboard']
      },
      {
        svg: '<svg class="mx-auto h-10 w-10 py-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">\n'+
            '<path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />\n'+
          '</svg>',
        title: 'appTabs.addActivity',
        route: 'add-activity',
        highlight_route_prefixes: ['add-activity']
      },
      {
        svg: '<svg class="mx-auto h-8 w-8 py-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">\n' +
          '  <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />\n' +
          '  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />\n' +
          '</svg>',
        title: 'appTabs.settings',
        route: 'settings',
        highlight_route_prefixes: ['settings']
      }
    ]
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
    })
  },
  methods: {
    isActiveTab (tab) {
      this.activeTab = tab
    }
  }
}
</script>
