<template>
  <div class="flex rounded-md p-4 relative" :class="[backgroundColorClass,{ 'p-4': padding }]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    padding: {
      type: Boolean,
      default: true
    },
    backgroundColorClass: {
      type: String,
      default: 'bg-white'
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>
