import { Preferences } from '@capacitor/preferences'

import * as types from '../mutation-types'

async function getPreference (key) {
  const result = await Preferences.get({ key });
  return result.value ? JSON.parse(result.value) : null;
}

async function setPreference (key, value) {
  return await Preferences.set({ key, value: JSON.stringify(value) });
}

// state
export const state = {
    hasRun: false,
}

// getters
export const getters = {
  hasRun: state => state.hasRun
}

// mutations
export const mutations = {
  [types.SET_HAS_RUN](state, hasRun) {
    state.hasRun = hasRun;
  }
};

// actions
export const actions = {
  async setHasRun ({ commit }, payload) {
    await setPreference('hasRun', payload.hasRun)
    commit(types.SET_HAS_RUN, payload.hasRun);
  }
}