// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// readSensorState.js
export const SET_HAS_RUN = 'SET_HAS_RUN';

// sensor.js
export const FETCH_SENSOR_SUCCESS = 'FETCH_SENSOR_SUCCESS';
export const FETCH_SENSOR_FAILURE = 'FETCH_SENSOR_SUCCESS';

// onboardingSteps.js
export const SAVE_DAILY_DASHBOARD = 'SAVE_DAILY_DASHBOARD';
export const SAVE_ACTIVITY_LOG = 'SAVE_ACTIVITY_LOG';
export const FETCH_ONBOARDING_STEPS_SUCCESS = 'FETCH_ONBOARDING_STEPS_SUCCESS';
export const FETCH_ONBOARDING_STEPS_FAILURE = 'FETCH_ONBOARDING_STEPS_FAILURE';
export const UPDATE_DAILYDASHBOARD = 'UPDATE_DAILYDASHBOARD';
export const UPDATE_ACTIVITYLOG = 'UPDATE_ACTIVITYLOG';

// crisp.js
export const SET_IS_OPEN = 'SET_IS_OPEN';