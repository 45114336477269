<template>
  <a v-if="href" :class="btnClasses" :href="href" :target="target">
    <span class="no-underline mx-auto">
      <slot />
    </span>
    <svg v-if="arrow" class="ml-2 w-3 h-3 inline" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 11L11 1M11 1H1M11 1V11" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"
      />
    </svg>
  </a>
  <router-link v-else-if="to" :class="btnClasses" :to="to" :target="target">
    <span class="no-underline mx-auto">
      <slot />
    </span>
    <svg v-if="arrow" class="ml-2 w-3 h-3 inline" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 11L11 1M11 1H1M11 1V11" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"
      />
    </svg>
  </router-link>
  <button v-else ref="button" :type="nativeType" :disabled="loading"
          :class="btnClasses" :style="buttonStyle" @click="onClick($event)"
  >
    <template v-if="!loading">
      <span class="no-underline mx-auto">
        <slot />
      </span>
      <svg v-if="arrow" class="ml-2 w-3 h-3 inline" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 11L11 1M11 1H1M11 1V11" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round"
        />
      </svg>
    </template>
    <loader v-else class="h-6 w-6 mx-auto" :class="`text-${colorShades['text']}`" />
  </button>
</template>

<script>
export default {
  name: 'VButton',

  props: {
    color: {
      type: String,
      default: 'white'
    },

    size: {
      type: String,
      default: 'medium'
    },

    nativeType: {
      type: String,
      default: null
    },

    loading: {
      type: Boolean,
      default: false
    },

    preserveWidth: {
      type: Boolean,
      default: false
    },

    arrow: {
      type: Boolean,
      default: false
    },

    to: {
      type: Object,
      default: null
    },

    href: {
      type: String,
      default: null
    },

    target: {
      type: String,
      default: '_self'
    }
  },

  data: function () {
    return {
      btnWidth: null
    }
  },

  computed: {
    buttonStyle () {
      if (this.btnWidth) {
        return {
          'min-width': this.btnWidth + 'px'
        }
      }
      return {
        'min-width': '0px'
      }
    },
    btnClasses () {
      const sizes = this.sizes
      const colorShades = this.colorShades
      return `${sizes['p-y']} ${sizes['p-x']}
        ${colorShades?.main} ${colorShades?.hover} ${colorShades?.ring} ${colorShades['ring-offset']}
        ${colorShades?.text} transition-all ease-in duration-200 text-center text-${sizes?.font} font-medium focus:outline-none focus:ring-2
        focus:ring-offset-2 rounded-lg flex items-center hover:no-underline`
    },
    colorShades () {
      if (this.color === 'light-purple') {
        return {
          main: 'bg-blue-600',
          hover: 'hover:bg-blue-700',
          ring: 'focus:ring-blue-500',
          'ring-offset': 'focus:ring-offset-blue-200',
          text: 'text-white'
        }
      } else if (this.color === 'transparent') {
        return {
          main: 'bg-transparent border border-gray-300',
          hover: 'hover:bg-white/20',
          ring: 'focus:ring-white-500',
          'ring-offset': 'focus:ring-offset-white-200',
          text: 'text-gray-700'
        }
      } else if (this.color === 'white') {
        return {
          main: 'bg-white',
          hover: 'hover:bg-gray-50',
          ring: 'focus:ring-white',
          'ring-offset': 'focus:ring-offset-white',
          text: 'text-gray-900'
        }
      } else if (this.color === 'purple') {
        return {
          main: 'bg-deeplome-purple',
          hover: 'hover:opacity-90',
          ring: 'focus:ring-purple-700',
          'ring-offset': 'focus:ring-purple-600',
          text: 'text-white'
        }
      } else if (this.color === 'black') {
        return {
          main: 'bg-black',
          hover: 'hover:bg-gray-800',
          ring: 'focus:ring-gray-900',
          'ring-offset': 'focus:ring-gray-900',
          text: 'text-white'
        }
      } else if (this.color === 'green') {
        return {
          main: 'bg-green-500',
          hover: 'hover:bg-green-600',
          ring: 'focus:ring-green-600',
          'ring-offset': 'focus:ring-green-900',
          text: 'text-white font-semibold'
        }
      } else if (this.color === 'red') {
        return {
          main: 'bg-red-500',
          hover: 'hover:bg-red-600',
          ring: 'focus:ring-red-600',
          'ring-offset': 'focus:ring-red-900',
          text: 'text-white font-semibold'
        }
      } else if (this.color === 'blue') {
        return {
          main: 'bg-glucie-blue',
          hover: 'hover:bg-blue-700',
          ring: 'focus:ring-blue-700',
          'ring-offset': 'focus:ring-blue-900',
          text: 'text-white'
        }
      } else if (this.color === 'orange') {
        return {
          main: 'bg-glucie-orange',
          hover: 'hover:bg-orange-400',
          ring: 'focus:ring-orange-700',
          'ring-offset': 'focus:ring-orange-900',
          text: 'text-gray-900'
        }
      } else if (this.color === 'snapchat') {
        return {
          main: 'bg-yellow-300',
          hover: 'hover:bg-yellow-400',
          ring: 'focus:ring-yellow-400',
          'ring-offset': 'focus:ring-yellow-400',
          text: 'text-black'
        }
      }
      console.error('Unknown color')
    },
    sizes () {
      if (this.size === 'small') {
        return {
          font: 'sm',
          'p-y': 'py-2',
          'p-x': 'px-3'
        }
      }
      return {
        font: 'base',
        'p-y': 'py-3',
        'p-x': 'px-3'
      }
    }
  },

  watch: {
    preserveWidth () {
      this.btnWidth = null
    }
  },

  methods: {
    onClick (event) {
      if (this.preserveWidth) {
        this.btnWidth = this.$refs.button.offsetWidth
      }
      this.$emit('click', event)
    }
  }
}
</script>
