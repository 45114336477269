import axios from 'axios'
import { Preferences } from '@capacitor/preferences'

import * as types from '../mutation-types'

async function getPreference (key) {
  const result = await Preferences.get({ key });
  return result.value ? JSON.parse(result.value) : null;
}

async function setPreference (key, value) {
  return await Preferences.set({ key, value: JSON.stringify(value) });
}

async function removePreference (key) {
  return await Preferences.remove({ key: key })
}

// state
export const state = {
  user: null,
  token: null
}
getPreference('token').then((token) => {
  state.token = token
})
getPreference('user').then((user) => {
  state.user = user
})

// getters
export const getters = {
  user: state => state.user,
  token: state => state.token,
  check: state => state.user !== null
}

// mutations
export const mutations = {
  [types.SAVE_TOKEN] (state, { token }) {
    state.token = token
  },

  [types.FETCH_USER_SUCCESS] (state, { user }) {
    state.user = user
  },

  async [types.FETCH_USER_FAILURE] (state) {
    state.token = null
  },

  async [types.LOGOUT] (state) {
    state.user = null
    state.token = null
  },

  [types.UPDATE_USER] (state, { user }) {
    state.user = user
  }
}

// actions
export const actions = {
  async saveToken ({ commit, dispatch }, payload) {
    commit(types.SAVE_TOKEN, payload)
    await setPreference('token', payload.token)
  },
  async getToken(){
    return await getPreference('token')
  },
  async getUser(){
    return await getPreference('user')
  },
  async fetchUser ({ commit, dispatch }) {
    try {
      const { data } = await axios.get('/user')
      const user = data.data
      commit(types.FETCH_USER_SUCCESS, { user: user })
      await setPreference('user', user);
      return user
    } catch (e) {
      commit(types.FETCH_USER_FAILURE)
      await removePreference('token');
    }
  },
  async updateUser ({ commit }) {
    const { data } = await axios.get('/user')
    const user = data.data
    commit(types.UPDATE_USER, { user: user })
    setPreference('user', user);
    return user
  },
  async logout ({ commit }) {
    try {
      await axios.post('/logout')
    } catch (e) { }
    commit(types.LOGOUT);
    await removePreference('token');
  },
  async fetchOauthUrl (ctx, { provider }) {
    const { data } = await axios.post(`/oauth/${provider}`)

    return data.url
  }
}