import Vue from 'vue'

import PortalVue from 'portal-vue'
import Notifications from 'vt-notifications'
import Scrollspy from 'vue2-scrollspy'

import VueAnimXYZ from '@animxyz/vue'
import '@animxyz/core' // Import css here if you haven't elsewhere

Vue.use(PortalVue)

Vue.use(Notifications)

Vue.use(Scrollspy)

Vue.use(VueAnimXYZ)

Vue.prototype.$getCrisp = () => {
  return window.$crisp
}
