import axios from 'axios'
import { Preferences } from '@capacitor/preferences'
import{ getOnboardingSteps } from '../../helpers/dataFetcher'
import * as types from '../mutation-types'

async function getPreference (key) {
  const result = await Preferences.get({ key });
  return result.value ? JSON.parse(result.value) : null;
}

async function setPreference (key, value) {
  return await Preferences.set({ key, value: JSON.stringify(value) });
}

async function removePreference (key) {
  return await Preferences.remove({ key: key })
}

// state
export const state = {
    dailyDashboard: null,
    activityLog: null
}
getPreference('dailyDashboard').then((dailyDashboard) => {
  state.dailyDashboard = dailyDashboard
})
getPreference('activityLog').then((activityLog) => {
  state.activityLog = activityLog
})

// getters
export const getters = {
    dailyDashboard: state => state.dailyDashboard,
    activityLog: state => state.activityLog
}

// mutations
export const mutations = {
  [types.SAVE_DAILY_DASHBOARD] (state, dailyDashboard) {
    state.dailyDashboard = dailyDashboard
  },

  [types.SAVE_ACTIVITY_LOG] (state, activityLog) {
    state.activityLog = activityLog
  },

  [types.FETCH_ONBOARDING_STEPS_SUCCESS] (state, { onboardingSteps }) {
    state.dailyDashboard = onboardingSteps.daily_dashboard
    state.activityLog = onboardingSteps.activity_log
  },

  [types.UPDATE_DAILYDASHBOARD] (state, dailyDashboard) {
    state.dailyDashboard = dailyDashboard
  },

  [types.UPDATE_ACTIVITYLOG] (state, activityLog) {
    state.activityLog = activityLog
  }
}

// actions
export const actions = {
  async saveDailyDashboard ({ commit, dispatch }, payload) {
        commit(types.SAVE_DAILY_DASHBOARD, payload.dailyDashboard)
        await setPreference('dailyDashboard', payload.dailyDashboard)
  },
  async saveActivityLog ({ commit, dispatch }, payload) {
        commit(types.SAVE_ACTIVITY_LOG, payload.activityLog)
        await setPreference('activityLog', payload.activityLog)
  },
  async fetchOnboardingSteps ({ commit, dispatch }) {
        const onboardingSteps = await getOnboardingSteps()
        commit(types.FETCH_ONBOARDING_STEPS_SUCCESS, { onboardingSteps })
        await setPreference('dailyDashboard', onboardingSteps.daily_dashboard)
        await setPreference('activityLog', onboardingSteps.activity_log)
        return onboardingSteps
  },
  async updateDailyDashboard({ commit, dispatch }, payload) {
    axios.post('/onboarding/', {daily_dashboard: payload.dailyDashboard}).then(async () => {
        commit(types.UPDATE_DAILYDASHBOARD, payload.dailyDashboard)
        await setPreference('dailyDashboard', payload.dailyDashboard)
    })
  },
  async updateActivityLog({ commit, dispatch }, payload) {
    axios.post('/onboarding/', {activity_log: payload.activityLog}).then(async () => {
        commit(types.UPDATE_ACTIVITYLOG, payload.activityLog)
        await setPreference('activityLog', payload.activityLog)
    })
  }
}