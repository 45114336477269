export function resizeAndBase64EncodeImage(file, desiredWidth = 300, desiredHeight = 300) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const img = new Image();

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                canvas.width = desiredWidth;
                canvas.height = desiredHeight;

                ctx.drawImage(img, 0, 0, desiredWidth, desiredHeight);

                canvas.toBlob((blob) => {
                    const pngFile = new File([blob], file.name.replace(/\.[^/.]+$/, ".png"), { type: "image/png" });
                    resolve(pngFile);
                }, "image/png");
            };

            img.onerror = reject;
            img.src = event.target.result;
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}