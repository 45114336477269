<template>
  <div class="main-layout min-h-screen flex flex-col bg-white relative pb-20"
       :style="{paddingBottom: isMobileApp ? paddingBottom+'px':'0px', paddingTop:paddingTop+'px'}"
  >
    <div class="w-full bg-white fixed top-0 z-10" :style="{height:paddingTop+'px'}" />

    <child class="flex-grow" :style="{paddingBottom: paddingBottom+'px'}" />
    <app-tabs class="w-full fixed bottom-0" />
  </div>
</template>

<script>
import { SafeArea } from 'capacitor-plugin-safe-area'
import AppTabs from '../components/AppTabs.vue'
export default {
  name: 'MainLayout',

  components: {
    AppTabs
  },

  data: function () {
    return {
      paddingTop: 0,
      paddingBottom: 0
    }
  },

  mounted () {
    this.setStatusBarStyle('light')
    try {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        this.paddingTop = insets.top
        this.paddingBottom = insets.bottom
      })
    } catch (e) {
    }
  },

  methods: {}
}
</script>
