export function formatDateTime (date) {
    date = new Date(date + 'Z')
    return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
}

export function addTwoHoursToDate (date) {
    date = new Date(date + 'Z')
    return date.getHours() + 2 + ':' + date.getMinutes().toString().padStart(2, '0')
}


export function formatDateTimeUTC (date) {
    date = new Date(date)
    return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
}

export function formatDateString (inputDate) {
    const parsedDate = new Date(inputDate)
    const reformattedDate = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate() + 1, 0, 0, 0, 0)
    const formattedDateString = reformattedDate.toISOString().split('T')[0]
    return formattedDateString
}