<template>
  <div class="w-full md:max-w-5xl md:mx-auto my-20 px-8">
    <div class="w-full md:w-1/2 mb-16">
      <h1 class="text-3xl font-bold">
        {{ $t('pageNotFound.title') }}
      </h1>
      <p class="mt-1 text-gray-500">{{ $t('pageNotFound.description') }}</p>
      <v-button color="white" @click="goToHome" class="mt-10" >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
             stroke="currentColor" class="w-4 h-4 inline">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
        </svg>
        {{ $t('pageNotFound.cta') }}
      </v-button>
    </div>
    <div class="w-full md:w-1/2">
      <img :src="asset('img/404.gif')" alt="404" class="w-full rounded-lg shadow-lg" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFound',

  data: () => ({
  }),
  methods: {
    goToHome () {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
