function page (path) {
  const pages = import.meta.glob('../pages/**')
  return pages[`../pages/${path}`]
}

export default [
  // Logged Users
  { path: '/home', name: 'home', component: page('home.vue') },
  { path: '/sensor', name: 'sensor', component: page('sensor.vue') },
  { path: '/nfc', name: 'nfc', component: page('nfc.vue') },
  { path: '/onboarding/form', name: 'onboarding-form', component: page('onboarding-form.vue') },
  { path: '/register', name: 'register', component: page('register.vue') },
  { path: '/daily-dashboard', name: 'daily-dashboard', component: page('daily-dashboard.vue') },
  { path: '/recipe', name: 'recipe', component: page('recipe.vue') },
  { path: '/add-activity', name: 'add-activity', component: page('activity-form.vue') },
  { path: '/score/:id', name: 'score', component: page('meal-log/score.vue') },
  { path: '/meal/:id', name: 'meal', component: page('meal-log/meal.vue') },
  { path: '/meal/edit/:id', name: 'meal-edit', component: page('meal-log/edit-meal.vue') },
  { path: '/exercise/:id', name: 'exercise', component: page('exercise-log/exercise.vue') },
  { path: '/exercise/edit/:id', name: 'exercise-edit', component: page('exercise-log/edit-exercise-log.vue') },
  { path: '/sleep/:id', name: 'sleep', component: page('sleep-log/sleep.vue') },
  { path: '/sleep/edit/:id', name: 'sleep-edit', component: page('sleep-log/edit-sleep-log.vue') },
  { path: '/apply-sensor', name: 'apply-sensor', component: page('libre-link-up/how-to-apply-sensor.vue') },
  { path: '/pair-sensor', name: 'pair-sensor', component: page('libre-link-up/how-to-pair-sensor.vue') },
  { path: '/remove-sensor', name: 'remove-sensor', component: page('libre-link-up/how-to-remove-sensor.vue') },
  { path: '/libre-link-up', name: 'libre-link-up', component: page('libre-link-up/libre-link-up-form.vue') },
  { path: '/settings', name: 'settings', component: page('settings/index.vue') },
  { path: '/settings/profile', name: 'settings-profile', component: page('settings/profile.vue') },
  { path: '/settings/food/restrictions', name: 'settings-food-restrictions', component: page('settings/food-restrictions.vue') },
  { path: '/settings/goals', name: 'settings-goals', component: page('settings/goals.vue') },
  { path: '/settings/macros', name: 'settings-macros', component: page('settings/macro-objectives.vue') },
  { path: '/nutritionist/chat', name: 'nutritionist-chat', component: page('chats/nutritionist-chat.vue') },
  { path: '/support/chat', name: 'support-chat', component: page('chats/support-chat.vue') },
  { path: '/settings/notifications', name: 'settings-notifications', component: page('settings/notifications-preferences.vue') },
  { path: '/settings/account', name: 'settings-account', component: page('settings/account.vue') },
  { path: '/settings/subscription', name: 'settings-subscription', component: page('settings/subscription.vue') },
  { path: '/pricing', name: 'pricing', component: page('pricing.vue') },
  { path: '/settings/admin', name: 'settings-admin', component: page('settings/admin.vue') },
  { path: '/subscription/expired', name: 'subscription-expired', component: page('subscription-expired.vue') },
  { path: '/faqs', name: 'faqs', component: page('settings/Faq.vue') },
  { path: '/rgpd', name: 'rgpd', component: page('legal-help/rgpd.vue') },
  { path: '/cgv', name: 'cgv', component: page('legal-help/cgv.vue') },

  // Guest Routes
  { path: '/', name: 'welcome', redirect: { name: 'home' } },
  { path: '/onboarding', name: 'onboarding', component: page('onboarding.vue') },
  { path: '/password/forgot', name: 'password.forgot', component: page('auth/forgot-password.vue') },
  { path: '/signup', name: 'signup', component: page('auth/signup.vue') },
  { path: '/pwd/token', name: 'pwd.token', component: page('auth/pwd-token.vue') },
  { path: '/password/reset/token', name: 'pwd.reset.token', component: page('auth/pwd-reset-token.vue') },
  { path: '/password/reset', name: 'password.reset', component: page('auth/reset-password.vue') },
  { path: '/login', name: 'login', component: page('login.vue') },

  { path: '*', component: page('errors/404.vue') }
]
